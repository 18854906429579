<template>
  <div class="bbr-user-program--intake-change">
    <v-row class="d-flex align-stretch">
      <v-col cols="12" sm="4">
        <v-select
          v-model="program"
          class="text-capitalize"
          :label="`Current ${programTypeText}`"
          :items="availablePrograms"
          :disabled="
            isDeactivated || !hasPrograms || !this.changeProgram || isCompleted
          "
          item-value="id"
          item-text="name"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          v-model="variant"
          :label="`Validity Variants`"
          :items="availableVariants"
          :disabled="
            isDeactivated || !hasPrograms || !this.changeProgram || isCompleted
          "
          :error-messages="$getError('variant_id')"
          :hide-details="!$getError('variant_id')"
          item-value="id"
          item-text="validity"
          outlined
          flat
        >
          <template v-slot:item="{ item }">
            <span> {{ item.validity }} Month Validity </span>
          </template>

          <template v-slot:selection="{ item }">
            <span> {{ item.validity }} Month Validity </span>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" sm="5" class="text-right">
        <v-btn
          v-if="!changeProgram"
          class="px-10"
          color="primary"
          :loading="loading"
          :disabled="isDeactivated || !hasPrograms || isCompleted"
          @click="changeProgram = true"
          text
        >
          Change Round & Variant
        </v-btn>

        <v-btn
          v-if="changeProgram && !loading"
          class="px-5 mr-2"
          color="warning"
          @click="confirmed(false)"
          text
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="changeProgram"
          class="px-10"
          color="success"
          :loading="loading"
          @click="updateProgram"
          text
        >
          Update Round & Variant
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!isCompleted && !hasPrograms">
      <v-col>
        <p>
          No available {{ programTypeText }}s to override under the
          {{ programTypeText }}'s current plan.
        </p>
      </v-col>
    </v-row>

    <v-divider class="mt-8 mb-3" />

    <v-toast ref="errorToast" />
    <confirm-dialog ref="confirmChangeProgram" @confirmed="confirmed" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import VToast from '@/components/elements/Toast'

import { mapActions, mapState } from 'vuex'
import { filter } from 'lodash'

export default {
  name: 'ChangeUserProgram',

  components: {
    VToast,
    ConfirmDialog,
  },

  props: {
    userId: {
      type: [Number, String],
      required: true,
    },

    programId: {
      type: [Number, String],
      required: true,
    },

    availablePrograms: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
      variant: null,
      program: Number(this.programId),
      loading: false,
      changeProgram: false,
    }
  },

  computed: {
    ...mapState({
      selectedProgram: (state) => state.userProfile.selectedProgram,
    }),

    status() {
      return this.selectedProgram
        ? this.selectedProgram.user_program.status
        : null
    },

    isCompleted() {
      return this.status === 'completed' || false
    },

    hasPrograms() {
      return !!this.availablePrograms.length
    },

    isDeactivated() {
      return this.selectedProgram
        ? !!this.selectedProgram.user_program.deactivated_at
        : false
    },

    programType() {
      return this.program ? this.program.type : null
    },

    programTypeText() {
      return this.programType === 1 ? 'program' : 'challenge'
    },

    availableVariants() {
      if (!this.program) return []

      const program = filter(this.availablePrograms, {
        id: this.program,
      })

      return program.length ? program[0]['variants'] : []
    },
  },

  methods: {
    ...mapActions({
      changeUserProgram: 'userProfile/changeUserProgram',
    }),

    confirmed(confirmed) {
      if (!confirmed) {
        this.errors = {}
        this.changeProgram = false
        this.program = Number(this.programId)
      }
    },

    async updateProgram() {
      const confirm = await this.$refs.confirmChangeProgram.open(
        'Change User Current Program',
        'Are you sure you want to change the current user program?'
      )

      if (!confirm) return

      this.loading = true

      await this.changeUserProgram({
        userId: this.userId,
        programId: this.programId,
        newProgram: this.program,
        variantId: this.variant,
      })
        .then(({ data }) => {
          setTimeout(() => {
            this.loading = false

            this.confirmed(false)

            this.$router.back(-1)
          }, 800)
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.loading = false

            if (response && response.status === 422) {
              this.errors = response.data.errors
            } else {
              this.$refs.errorToast.open({
                color: 'red',
                message: response.data.message,
              })
            }
          }, 800)
        })
    },

    $getError(field) {
      return this.errors[field]
    },
  },

  watch: {
    programId(value) {
      this.program = Number(value)
    },

    availableVariants() {
      if (this.availableVariants.length === 1) {
        this.variant = this.availableVariants[0]['id']
      }
    },
  },
}
</script>
