<template>
  <div class="user-profile--programs-details mb-12 pb-12">
    <v-row>
      <v-col cols="12" md="12" lg="8" style="position: relative">
        <div v-if="loading" class="mx-3 py-14">
          <v-overlay
            class="mx-3 py-12 rounded-lg"
            :opacity="0.3"
            :value="loading"
            absolute
          >
            <v-progress-circular indeterminate size="64" />
          </v-overlay>
        </div>

        <div v-if="!loading" class="d-flex align-center">
          <h2 class="mr-auto">
            <span v-if="selectedProgram" class="primary--text">
              {{ selectedProgram.name }}
            </span>
            - Program Details
          </h2>

          <v-btn
            class="bg-primary-gradient primary mr-2"
            depressed
            text
            v-if="canBeSetAsDefault"
            :loading="busy"
            @click="setDefault(selectedProgram.user_program.id)"
          >
            <v-icon>{{ icons.checkBold }}</v-icon>
            Set as default
          </v-btn>
          <v-btn
            class="bg-primary-gradient primary back-button px-12"
            @click="$router.back(1)"
            depressed
            text
          >
            <v-icon> {{ icons.back }} </v-icon>
            Go Back
          </v-btn>
        </div>

        <div v-if="!loading" class="user-profile--programs-details mt-8">
          <program-details-form :form-data="form" />
        </div>
      </v-col>
      <v-toast ref="toast" />
    </v-row>
  </div>
</template>

<script>
import ProgramDetailsForm from '../components/ProgramDetailsForm'
import VToast from '@/components/elements/Toast'
import Form from '@/utils/form'
import dayjs from 'dayjs'
import { values, flattenDeep } from 'lodash'
import { mapActions, mapState, mapMutations } from 'vuex'
import { mdiArrowLeft, mdiCheckBold } from '@mdi/js'

export default {
  name: 'UserProgramDetails',

  components: {
    ProgramDetailsForm,
    VToast,
  },

  data() {
    return {
      loading: true,
      overlay: true,

      icons: {
        back: mdiArrowLeft,
        checkBold: mdiCheckBold,
      },

      form: new Form({
        starts_at: '',
        valid_until: '',
        rounds: 0,
        program_id: null,
      }),

      busy: false,
      isDefault: false,
    }
  },

  async created() {
    this.fetchProgram()
    const timer = setInterval(() => {
      if (this.selectedProgram) {
        this.isDefault = this.selectedProgram.user_program.is_default
        clearInterval(timer)
      }
    }, 300)
  },

  destroyed() {
    this.clearSelectedProgram()
    this.isDefault = false
  },

  computed: {
    ...mapState({
      selectedProgram: (state) => state.userProfile.selectedProgram,
    }),
    canBeSetAsDefault() {
      return (
        !this.isDefault && !this.selectedProgram.user_program.deactivated_at
      )
    },
  },

  watch: {
    selectedProgram: function(program) {
      this.form.starts_at =
        program.user_program.starts_at &&
        dayjs.utc(program.user_program.starts_at).format('YYYY-MM-DD')

      this.form.valid_until = dayjs
        .utc(program.user_program.valid_until)
        .format('YYYY-MM-DD')

      this.form.program_id = program.id
    },
  },

  methods: {
    ...mapActions({
      getProgramDetails: 'userProfile/getProgramDetails',
      assignDefaultProgram: 'users/assignDefaultProgram',
    }),

    ...mapMutations({
      clearSelectedProgram: 'userProfile/clearSelectedProgram',
    }),

    async fetchProgram() {
      this.loading = true

      await this.getProgramDetails(this.$route.params)

      await this.$sleep()

      this.loading = false
    },

    async setDefault(userProgramId) {
      this.busy = true
      try {
        await this.assignDefaultProgram({
          userId: this.$route.params.id,
          userProgramId,
        })

        this.$refs.toast.open({
          color: 'primary',
          message: 'Successfully assign new default program.',
        })
        this.busy = false
        this.isDefault = true
      } catch (err) {
        this.busy = false
        if (err) {
          if (err.response.data !== undefined) {
            this.$refs.toast.open({
              color: 'red',
              timeout: 3000,
              message: flattenDeep(values(err.response.data.errors)).join(' '),
            })
          }
        }
      }
    },
  },
}
</script>

<style lang="scss">
.breadcrumbs {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
