<template>
  <div class="bbr-user-program--intake-change">
    <v-row class="d-flex align-stretch">
      <v-col cols="12" sm="4">
        <v-menu
          v-model="intakeDateMenu"
          :close-on-content-click="false"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="programIntake"
              hide-details
              :disabled="disableActions"
              label="Intake Date"
              outlined
              readonly
              flat
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="programIntake"
            :allowed-dates="allowedStartDates"
            scrollable
            no-title
            @input="intakeDateMenu = false"
          />
        </v-menu>
      </v-col>
      <v-col v-if="programValidUntil" cols="12" sm="4">
        <v-menu
          v-model="validUntilDateMenu"
          :close-on-content-click="false"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="programValidUntil"
              :disabled="disableActions"
              label="Valid Date"
              hide-details
              outlined
              readonly
              flat
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="programValidUntil"
            scrollable
            no-title
            @input="validUntilDateMenu = false"
          />
        </v-menu>
      </v-col>

      <v-col cols="12" :sm="programValidUntil ? 2 : 8" class="text-right">
        <v-btn
          v-if="!changeIntake"
          class="px-10"
          color="primary"
          :loading="loading"
          :disabled="isDeactivated"
          text
          @click="changeProgramIntake"
        >
          Change {{ programTypeText }} Intake
        </v-btn>

        <v-btn
          v-if="(changeIntake || changeValidUntil) && !loading"
          class="px-5 mr-2"
          color="warning"
          text
          @click="confirmed(false)"
        >
          Cancel
        </v-btn>

        <v-btn
          v-if="changeIntake"
          class="px-10"
          color="success"
          :loading="loading"
          text
          @click="updateIntake"
        >
          Update {{ programTypeText }} Intake
        </v-btn>
      </v-col>
    </v-row>

    <v-divider class="mt-5 mb-8" />

    <confirm-dialog ref="confirmChangeIntake" @confirmed="confirmed" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjs from 'dayjs'

import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'ChangeUserProgramIntake',

  components: {
    ConfirmDialog,
  },

  props: {
    intake: {
      type: String,
    },

    validUntil: {
      type: String,
    },

    userId: {
      type: [Number, String],
      required: true,
    },

    programId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      errors: [],
      loading: false,
      changeIntake: false,
      changeValidUntil: false,
      intakeDateMenu: false,
      validUntilDateMenu: false,
      programIntake: this.intake,
      programValidUntil: this.validUntil,
    }
  },

  computed: {
    ...mapState({
      program: (state) => state.userProfile.selectedProgram,
    }),

    status() {
      return this.program ? this.program.user_program.status : null
    },

    isCompleted() {
      return this.status === 'completed' || false
    },

    isDeactivated() {
      return this.program ? !!this.program.user_program.deactivated_at : false
    },

    disableActions() {
      return !this.changeIntake || this.isDeactivated
    },

    programType() {
      return this.program ? this.program.type : null
    },

    programTypeText() {
      return this.programType === 1 ? 'program' : 'challenge'
    },
  },

  methods: {
    ...mapActions({
      changeUserProgramIntake: 'userProfile/changeUserProgramIntake',
    }),

    ...mapMutations({
      setSelectedProgram: 'userProfile/setSelectedProgram',
    }),

    allowedStartDates(date) {
      dayjs.extend(isSameOrAfter)

      let _day = Number(dayjs.utc(date).format('d'))

      return _day === 1 || false
    },

    confirmed(confirmed) {
      if (!confirmed) {
        this.changeIntake = false
        this.changeValidUntil = false
        this.programIntake = this.intake
        this.programValidUntil = this.validUntil
      }
    },

    changeProgramIntake() {
      this.changeIntake = true
    },

    async updateIntake() {
      const confirm = await this.$refs.confirmChangeIntake.open(
        'Change Program Intake & Valid Until',
        "Are you sure you want to change the intake & valid until dates of this program? <br> <br> Changing of user's intake valid until will reset user's available workouts, meals and checkins",
        {
          width: 400,
        }
      )

      if (!confirm) return

      this.loading = true

      await this.changeUserProgramIntake({
        userId: this.userId,
        programId: this.programId,
        intakeDate: this.programIntake,
        validUntilDate: this.programValidUntil,
      })
        .then(({ data }) => {
          setTimeout(() => {
            this.loading = false

            this.setSelectedProgram(data.data)

            this.confirmed(false)

            this.programIntake = dayjs
              .utc(data.data.user_program.starts_at)
              .format('YYYY-MM-DD')
            this.programValidUntil = dayjs
              .utc(data.data.user_program.valid_until)
              .format('YYYY-MM-DD')
          }, 800)
        })
        .catch(({ response }) => {
          setTimeout(() => {
            this.loading = false
          }, 800)
        })
    },
  },

  watch: {
    intake(intake) {
      this.programIntake = intake
    },
  },
}
</script>
