<template>
  <div class="bbr-user-program--deactivate d-flex align-center">
    <v-row class="d-flex align-center">
      <v-col cols="12" md="7">
        <h4>Deactivate {{ programTypeText }}</h4>
        <div>
          <p v-if="!isDeactivated">
            Once you deactivate this program, there is no going back. Please be
            certain.
          </p>
          <p v-else>
            <span v-if="willBeDeactivated">
              This {{ programTypeText }} will be deactivated on
            </span>
            <span v-else>
              This {{ programTypeText }} has been deactivated last
            </span>
            <span class="font-weight-bold">
              {{ deactivatedAt | formatDate('MMMM D, YYYY HH:mm a') }}
            </span>
          </p>
        </div>

        <div v-if="false" class="info--text">
          Cannot deactivate program, it has been completed last
          <span class="font-weight-bold">
            {{ completedAt | formatDate('MMMM D, YYYY HH:mm a') }}
          </span>
        </div>
      </v-col>

      <v-col cols="12" md="5" class="text-right">
        <v-btn
          v-if="!isDeactivated"
          class="px-10"
          color="primary"
          :loading="loading"
          :disabled="isDeactivated"
          @click="deactivate"
        >
          Deactivate {{ programTypeText }}
        </v-btn>

        <div v-else>
          <v-chip class="px-8" color="error">
            <span v-if="willBeDeactivated">
              This {{ programTypeText }} is for deactivation
            </span>
            <span v-else>
              This {{ programTypeText }} has been deactivated
            </span>
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDeactivation" @close="confirmed" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DeactivateUserProgram',

  components: {
    ConfirmDialog,
  },

  props: {
    userId: {
      type: [Number, String],
      required: true,
    },

    programId: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      program: (state) => state.userProfile.selectedProgram,
    }),

    status() {
      return this.program ? this.program.user_program.status : null
    },

    isCompleted() {
      if (this.program && !this.program.user_program.starts_at) {
        return false
      }

      return this.status === 'completed' || false
    },

    completedAt() {
      return this.isCompleted ? this.program.user_program.ends_at : null
    },

    isDeactivated() {
      return this.program ? !!this.program.user_program.deactivated_at : false
    },

    willBeDeactivated() {
      return (
        this.program &&
        this.program.user_program.deactivated_at &&
        !this.program.user_program.is_deactivated
      )
    },

    programType() {
      return this.program ? this.program.type : null
    },

    programTypeText() {
      return this.programType === 1 ? 'program' : 'challenge'
    },

    deactivatedAt() {
      return this.program ? this.program.user_program.deactivated_at : false
    },

    isDefaultProgram() {
      return this.program ? this.program.user_program.is_default : false
    },
  },

  methods: {
    ...mapActions({
      deactivateUserProgram: 'userProfile/deactivateUserProgram',
    }),

    confirmed(confirmed) {
      return confirmed
    },

    async deactivate() {
      const confirm = await this.$refs.confirmDeactivation.open(
        'Deactivate Program',
        'Are you sure you want to deactivate this program? <br> Once you deactivate this program, there is no going back'
      )

      if (!confirm) return

      this.loading = true

      try {
        await this.deactivateUserProgram({
          userId: this.userId,
          programId: this.programId,
        })

        setTimeout(() => {
          this.loading = false
          this.$emit('deactivated', true)
        }, 900)
      } catch (err) {
        this.loading = false
      }
    },
  },
}
</script>
