<template>
  <div class="user-program--details-form">
    <v-alert v-if="deactivatedAt" type="error" text prominent>
      <v-row align="center">
        <v-col class="grow">
          <span v-if="willBeDeactivated">
            Program will be deactivated on
          </span>
          <span v-else>
            Program has been deactivated last
          </span>
          <span class="font-weight-bold">
            {{ deactivatedAt | formatDate('MMMM D, YYYY HH:mm a') }}.
          </span>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert v-if="isCompleted" type="success" text prominent>
      <v-row align="center">
        <v-col class="grow">
          Program has been completed last
          <span class="font-weight-bold">
            {{ completedAt | formatDate('MMMM D, YYYY HH:mm a') }}
          </span>
        </v-col>
      </v-row>
    </v-alert>

    <br />

    <change-user-program-intake
      :intake="form.starts_at"
      :validUntil="form.valid_until"
      :userId="$route.params.id"
      :programId="$route.params.programId"
    />

    <change-user-program
      :program="program"
      :user-id="$route.params.id"
      :program-id="$route.params.programId"
      :available-programs="planPrograms"
    />

    <deactivate-program
      :userId="$route.params.id"
      :programId="$route.params.programId"
      @deactivated="deactivated"
    />
  </div>
</template>

<script>
import ChangeUserProgramIntake from '@/components/elements/programs/ChangeUserProgramIntake'
import DeactivateProgram from '@/components/elements/programs/DeactivateUserProgram'
import ChangeUserProgram from '@/components/elements/programs/ChangeUserProgram'
import Form from '@/utils/form'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'ProgramDetailsForm',

  components: {
    ChangeUserProgram,
    DeactivateProgram,
    ChangeUserProgramIntake,
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      planPrograms: [],
      form: this.formData,

      loading: false,
      changeIntake: false,
      changeProgram: false,
      startDateMenu: false,
    }
  },

  computed: {
    ...mapState({
      program: (state) => state.userProfile.selectedProgram,
    }),

    isDeactivated() {
      return this.program ? !!this.program.user_program.deactivated_at : false
    },

    willBeDeactivated() {
      return this.deactivatedAt && !this.program.user_program.is_deactivated
    },

    deactivatedAt() {
      return this.program ? this.program.user_program.deactivated_at : false
    },

    programType() {
      return this.program ? this.program.type : null
    },

    isChallenge() {
      return this.programType === 2
    },

    status() {
      return this.program ? this.program.user_program.status : null
    },

    isCompleted() {
      if (this.program && !this.program.user_program.starts_at) {
        return false
      }

      return this.status === 'completed' || false
    },

    completedAt() {
      return this.isCompleted ? this.program.user_program.ends_at : null
    },
  },

  created() {
    this.fetchPlan()
  },

  methods: {
    ...mapActions({
      getPlan: 'plans/getPlan',
    }),

    async fetchPlan() {
      if (!this.program.package_id) return

      await this.getPlan(this.program.package_id).then(({ data }) => {
        this.planPrograms = data.programs
      })
    },

    deactivated() {
      this.$router.back(1)
    },
  },

  watch: {
    formData() {
      this.form = this.formData
    },

    program() {
      this.fetchPlan()
    },
  },
}
</script>
